import { StylesProvider, ThemeProvider, jssPreset } from "@material-ui/core";
import routes, { renderRoutes } from "src/routes";
import { AuthProvider } from "src/contexts/JWTAuthContext";
import GlobalStyles from "src/components/GlobalStyles";
import GoogleAnalytics from "src/components/GoogleAnalytics";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { useEffect,useContext  } from "react";
import { Router } from "react-router-dom";
import ScrollReset from "src/components/ScrollReset";
import { SnackbarProvider } from "notistack";
import { THEMES } from "src/constants";
import { create } from "jss";
import { createBrowserHistory } from "history";
import { createTheme } from "src/theme";
import rtl from "jss-rtl";
import { hugeiconsLicense } from "@hugeicons/react-pro";
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from './AppInsights';
import { v4 as uuidv4 } from 'uuid';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory();


const App = () => {

  hugeiconsLicense("150eafea8c7e2d8e99e4c1f89374d6e6RT00ODIzODY2OTc3MDAwLFM9cHJvLFY9MSxQPUd1bXJvYWQsU1Q9NTdBMTk4MEIsRVQ9NkY0ODNBNEQ=");

  const theme = createTheme({
    direction: "ltr",
    responsiveFontSizes: true,
    theme: THEMES.LIGHT
  });

  useEffect(() => {

    // Generate a new session ID (GUID)
    const sessionId = uuidv4();
    appInsights.context.session.id = sessionId;

    appInsights.trackEvent({
      name: 'Application On Load',
      properties: { 'Application Loaded': 'CGLicensePortal' }
    });

    // Track page views on route changes
    const unlisten = history.listen((location) => {
      appInsights.trackPageView({
        name: location.pathname,
        properties: { timestamp: Date.now() } // Pass the properties to trackPageView
      });
    });
    return () => {
      unlisten();
    };
  
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider maxSnack={3}>
            <Router history={history}>
              <AuthProvider>
                <GlobalStyles />
                <ScrollReset />
                <GoogleAnalytics />
                {renderRoutes(routes)}
              </AuthProvider>
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
};

export default App;
//export default withAITracking(reactPlugin, App);
