// src/AppInsights.js
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: '1ff1adec-9ed7-4942-8013-480a838a4ff6', // instrumentation key
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory }
    },
    disableFetchTracking: false,
    enableAutoRouteTracking: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    enablePerfMgr: true,    
    enableUnhandledPromiseRejectionTracking: true
  }
});

appInsights.loadAppInsights();

export { reactPlugin, appInsights };
